import axios, { AxiosInstance } from 'axios';

export const {
  REACT_APP_ADMIN_HOST: ADMIN_HOST,
} = process.env;

const headers = {
  "Content-type": "application/json"
}

export const adminApi: AxiosInstance = axios.create({
  baseURL: `${ADMIN_HOST}/api`,
  headers: headers,
});