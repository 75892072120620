import { adminApi } from '../../app/api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { IOpportunityResDto } from './opportunity.typs';

export const fetchOpportunitiesWithContent = createAsyncThunk<IOpportunityResDto[], void>(
  'opportunitiesWithContent/fetch',
  async (payload, {rejectWithValue}) => {
  try {
    const res = await adminApi.get('opportunities/aggregate');
    return res.data;
  } catch (error: any) {
    const message: string = error?.response?.data?.errorMessage ?? "fetch opportunities rejected.";
    return rejectWithValue(message);
  }
});

export const fetchOpportunities = createAsyncThunk<void, void>(
  'opportunities/fetch',
  async (payload, {rejectWithValue}) => {
  try {
    const res = await adminApi.get('opportunities');
    return res.data;
  } catch (error: any) {
    const message: string = error?.response?.data?.errorMessage ?? "fetch opportunities rejected.";
    return rejectWithValue(message);
  }
});