import * as React from "react";
import styled, { keyframes } from "styled-components";
import img from './asset/img.png';
import Footer from './Footer';
import { useCallback, useEffect, useRef, useState } from 'react';

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

function App() {
  const [email, setEmail] = useState<string>("");
  const [submitted, setSubmitted] = useState<"Pending" | "Success" | "Rejected" | "Idle">("Idle");
  const ref = useRef<ReturnType<typeof setInterval> | null>(null)
  const onSubmit = useCallback(async () => {
    if (submitted === "Pending") return;
    setSubmitted("Pending");
    try {
      const response = await fetch("https://n4s3pw6zhf.execute-api.us-east-1.amazonaws.com/live/email", {
        method: "PUT",
        body: JSON.stringify({email: email}),
        headers: {"Content-Type": "application/json"},
      })
      if (response.ok) {
        setSubmitted("Success");
      }
    } catch (error) {
      console.log(error)
      setSubmitted("Rejected");
    }
  }, [email, submitted]);

  useEffect(() => {
    if (submitted === "Rejected") {
      ref.current = setTimeout(() => {
        setSubmitted("Idle");
      }, 3000);
    }
    if (submitted === "Success") {
      setEmail("");
      ref.current = setTimeout(() => {
        setSubmitted("Idle");
      }, 3000);
    }
  }, [submitted])
  return (
    <Layout>
      <Back/>
      <Head>
        <Img
          loading="lazy"
          src={require("./asset/logo.svg").default}
        />
      </Head>
      <Section>
        <Heading>
          Welcome to ZimZam!
        </Heading>
        <div>
          <div>
            <FormHeading>Leave your email to get notified first when we launch!</FormHeading>
            <InputWrapper>
              <Input disabled={submitted === "Pending"} value={email}
                     onChange={({target: {value}}) => void setEmail(value)} type={'email'}/>
              {email && !emailRegex.test(email) && <span>Invalid email</span>}
              {submitted === "Success" && <span className={'success'}>Success!</span>}
              {submitted === "Rejected" && <span>Submit failed. Please try again</span>}
              <Button disabled={!emailRegex.test(email)} onClick={onSubmit}>APPLY!</Button>
            </InputWrapper>
          </div>
        </div>
      </Section>
      <Footer/>
    </Layout>
  );
}

const fadeInOut = keyframes`
    0%, 100% {
        opacity: 0.4;
    }
    65% {
        opacity: 1;
    }
`;

const Back = styled.div`
    width: 100vw;
    min-height: 100vh;
    background: url(${img}) no-repeat bottom;
    background-size: cover;
    position: absolute;
    z-index: 0;
    animation: ${fadeInOut} 6s infinite;

`;

const Layout = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
`;


const Img = styled.img`
    aspect-ratio: 5.56;
    object-fit: auto;
    object-position: center;
    width: 161px;
    max-width: 100%;
    margin: auto 0;
`;


const Head = styled.header`
    padding: 20px 40px;

    @media (max-width: 768px) {
        padding: 20px;
    }
`;

const Section = styled.section`
    padding: 20px 40px;

    @media (max-width: 768px) {
        padding: 20px;
    }
`;

const Heading = styled.h1`
    color: var(--ZimZam-Black, #1D1D1D);
    font-family: Chillax-Semibold;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 115%; /* 55.2px */
    text-align: center;
    margin-top: 40px;
    @media (max-width: 768px) {
        font-size: 36px;
    }
`;

const FormHeading = styled.h2`
    margin-top: 40px;
    leading-trim: both;
    text-edge: cap;
    font-family: Chillax-Bold;
    font-size: 24px;
    text-align: center;
    font-style: normal;
    font-weight: 700;
    line-height: 1.1;
    background: var(--Gradient, linear-gradient(91deg, #C42DC4 0%, #FE5D36 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    @media (max-width: 768px) {
        font-size: 20px;
    }
`;
const InputWrapper = styled.div`
    display: flex;
    position: relative;
    max-width: 745px;
    width: 100%;
    height: 70px;
    margin: 20px auto;

    > span {
        position: absolute;
        top: calc(100% + 5px);
        left: 35px;
        color: var(--ZimZam-Pink, #BD28D6);
        font-family: Satoshi-Black;

        &.success {
            color: #4BB543;
        }
    }

    @media (max-width: 768px) {
        height: 48px;
        > span {
            left: 15px
        }
    }
`;

const Input = styled.input`
    width: 100%;
    flex-shrink: 0;
    border-radius: 200px;
    background: var(--White, #FFF);
    border-radius: 200px;
    background: #FFF;
    border: 0;
    box-shadow: 0px 2px 24px 2px rgba(0, 0, 0, 0.04);
    color: #707070;
    leading-trim: both;
    text-edge: cap;
    font-family: Satoshi-Medium;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 20px 140px 20px 35px;
    @media (max-width: 768px) {
        padding: 20px 120px 20px 15px;
        font-size: 16px;
    }

    &:focus {
        outline: none;
        border: 1px solid var(--ZimZam-Purple, #BD28D6);
    }

`
const Button = styled.button`
    position: absolute;
    right: 0;
    border: 0;
    display: inline-flex;
    height: 100%;
    padding: 18px 32px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 200px;
    background: var(--ZimZam-Purple, #BD28D6);
    color: var(--zimzam_pink, #F7F0EE);
    leading-trim: both;
    text-edge: cap;
    font-family: Satoshi-Black;
    font-size: 20px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    cursor: pointer;

    &:disabled {
        opacity: 0.7;
    }

    @media (max-width: 768px) {
        font-size: 14px;
    }
`

export default App;
