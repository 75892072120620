import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import {
  fetchOpportunities, fetchOpportunitiesWithContent
} from './opportunitiesAPI';
import { IOpportunityResDto } from './opportunity.typs';

export interface OpportunitiesState {
  opportunities: IOpportunityResDto[] | null;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: OpportunitiesState = {
  opportunities: null,
  status: 'idle',
};

export const opportunitiesSlice = createSlice({
  name: 'opportunities',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOpportunities.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchOpportunities.fulfilled, (state: OpportunitiesState, action: PayloadAction<any>) => {
        state.status = 'idle';
        state.opportunities = action.payload;
      })
      .addCase(fetchOpportunities.rejected, (state:OpportunitiesState, action) => {
        state.status = 'failed';
      })
      .addCase(fetchOpportunitiesWithContent.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchOpportunitiesWithContent.fulfilled, (state: OpportunitiesState, action: PayloadAction<any>) => {
        state.status = 'idle';
        state.opportunities = action.payload;
      })
      .addCase(fetchOpportunitiesWithContent.rejected, (state:OpportunitiesState, action) => {
        state.status = 'failed';
      });
  },
});

export const actions = opportunitiesSlice.actions;

export const selectOpportunities = (state: RootState) => state.opportunities;

export default opportunitiesSlice.reducer;
