import * as React from "react";
import styled from "styled-components";
import './App.scss';

const Footer: React.FC = () => {
  return (
    <Div>
      <Div2>
        <Div3>
          <Div4>
            <Div5>
              <Column>
                <Div6>
                  <Img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/9ba29589a62c82aeb563fa9edca64b06bf7f97dd8eb32469572027e32a038cff?"
                  />
                </Div6>
              </Column>
              <Column2>
                <Div7>© ZimZam {new Date().getFullYear()}</Div7>
              </Column2>
            </Div5>
          </Div4>
        </Div3>
      </Div2>
    </Div>
  )
}

// Your styled components remain unchanged

export default Footer;


const Div = styled.div`
    display: flex;
    padding-top: 37px;
    flex-direction: column;
    position: absolute;
    width: 100%;
    bottom: 0;
`;

const Div2 = styled.div`
    background-color: #40283d;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 0 60px 20px;
    @media (max-width: 991px) {
        max-width: 100%;
        padding: 0 20px;
    }
`;

const Div3 = styled.div`
    z-index: 10;
    display: flex;
    margin-top: -37px;
    width: 100%;
    max-width: 1689px;
    gap: 20px;
    @media (max-width: 991px) {
        max-width: 100%;
        flex-wrap: wrap;
    }
`;

const Div4 = styled.div`
    flex-grow: 1;
    flex-basis: auto;
    @media (max-width: 991px) {
        max-width: 100%;
    }
`;

const Div5 = styled.div`
    gap: 20px;
    display: flex;
    @media (max-width: 991px) {
        flex-direction: column;
        align-items: stretch;
        gap: 0px;
    }
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 24%;
    margin-left: 0px;
`;

const Div6 = styled.div`
    border-radius: 18px;
    background-color: var(--White, #fff);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 152px;
    height: 152px;
    margin: 0 auto;
    padding: 0 38px;
    @media (max-width: 768px) {
        width: 120px;
        height: 120px;
        margin-left: 0;
    }
`;

const Img = styled.img`
    aspect-ratio: 1.1;
    object-fit: auto;
    object-position: center;
    width: 76px;
    fill: linear-gradient(180deg, #bd28d6 0.04%, #ff5e35 99.86%),
    var(--ZimZam-Pink, #f7f0ee);
`;

const Column2 = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    margin-left: 20px;
`;

const Div7 = styled.div`
    color: var(--White, #fff);
    font-family: Chillax-Semibold;
    text-align: right;
    padding: 0 10px 10px 0;
    @media (max-width: 768px) {
        font-size: 16px;
    }
`;